.menu{
  &__line, &__list, &__nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__nav{

  }
  &__list{
    width: 560px;
    &-item{

    }
    &-link{
      color: $white-default;
      font-weight: 600;
    }
  }
  &__burger{
    display: none;
  }
}

@include adaptive(tablet){
  .menu{
    &__list{
      display: none;
    }
    &__burger{
      display: block;
      margin-top: 9px;
      margin-right: 8px;
    }
  }
}

@include adaptive(mobile){
  .menu{
    &__burger{
      margin-top: 11px;
      margin-right: 4px;
    }
  }
}