.footer{
  padding: 36px 0 90px;
  &__logo{
    margin-top: -20px;
  }
  &__content{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__title{
    display: block;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__menu{
    width: 500px;
    &-list{
      display: flex;
      flex-wrap: wrap;
    }
    &-item{
      width: 33%;
      margin-bottom: 15px;
      &:nth-child(3n + 2){
        text-align: center;
      }
      &:nth-child(3n + 3){
        text-align: right;
      }
    }
  }
  &__contacts{
    margin-right: 7px;
    &-item{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    &-icon{
      margin-right: 20px;
    }
  }
}

@include adaptive(tablet){
  .footer{
    padding: 52px 0 60px;
    &__menu{
      width: 345px;
      margin-bottom: 26px;
      margin-right: 66px;
      &-item{
        a{
          font-size: 15px;
        }
      }
    }
    &__title{
      width: 100%;
      font-size: 16px;
      margin-bottom: 13px;
    }
    &__logo{
      margin-top: -8px;
      margin-right: -23px;
    }
    &__contacts{
      width: 345px;
      margin-right: 66px;
      margin-left: auto;
      display: flex;
      flex-wrap: wrap;
      &-item{
        width: 50%;
        margin-bottom: 0;
        font-size: 15px;
      }
      &-icon{
        margin-right: 5px;
      }
    }
    &__content{
      flex-wrap: wrap;
    }
  }
}

@include adaptive(mobile){
  .footer{
    padding-top: 35px;
    &__menu{
      width: 100%;
      margin-right: 0;
      margin-top: 18px;
      margin-bottom: 15px;
      &-item{
        width: 50%;
        margin-bottom: 11px;
        &:nth-child(3n + 3){
          text-align: left;
        }
        &:nth-child(3n + 2){
          text-align: left;
        }
      }
    }
    &__title{
      font-size: 13px;
    }
    &__contacts{
      &-item{
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}