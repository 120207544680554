.features{
  background: $white-default url('../img/features-bg.svg') no-repeat center;
  padding-top: 193px;
  padding-bottom: 150px;
  &__content{
    width: 607px;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    max-width: 100%;
  }
  &__img{
    position: absolute;
    right: calc(100% + 66px);
    top: -497px;
    &--tablet{
      display: none;
    }
  }
  &__desc{
    font-size: 32px;
    font-weight: 700;
    font-family: "Source Sans Pro", $font-stack;
    margin-top: 80px;
    margin-bottom: 32px;
  }
  &__title{
    margin-bottom: 35px;
  }
  &__list{
    .main-list__item{
      margin-bottom: 14px;
      font-size: 20px;
    }
  }
  &__brand-btn{
    min-height: 42px;
    margin-top: 28px;
    font-size: 16px;
    padding: 5px 10px;
  }
}

@include adaptive(tablet){
  .features{
    padding-top: 50px;
    padding-bottom: 60px;
    background: url('../img/features-square.svg') -620px -80px no-repeat;
    &__title{
      text-align: left;
      margin-bottom: 35px;
    }
    &__desc{
      font-size: 26px;
      margin-top: 48px;
      margin-bottom: 35px;
    }
    &__img{
      position: static;
      width: 100%;
      margin-bottom: 70px;
      &--desktop{
        display: none;
      }
      &--tablet{
        display: block;
      }
    }
    &__list{
      max-width: 80%;
      .main-list__item{
        font-size: 16px;
        margin-bottom: 19px;
      }
    }
    &__brand-btn{
      margin-top: 15px;
      width: 220px;
    }
  }
}

@include adaptive(mobile){
  .features{
    &__img{
      margin-bottom: 89px;
    }
    &__title{
      margin-bottom: 32px;
    }
    &__list{
      max-width: 100%;
      .main-list__item{
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
    &__desc{
      &--mini{
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
    &__brand-btn{
      margin: 0 auto;
      font-size: 14px;
    }
  }
}

@include adaptive(mobile){
  .features{
    background-position: -700px -170px;
  }
}