.benefits{
  background: $grey url('../img/graphs-bg.svg') center 247px;
  padding-top: 70px;
  padding-bottom: 182px;
  overflow-x: hidden;
  &__content{
    margin-top: 108px;
  }
}

@include adaptive(tablet){
  .benefits{
    padding-top: 53px;
    padding-bottom: 75px;
    -ms-background-position-y: 0;
    background-position-y: 0;
    &__content{
      margin-top: 43px;
    }
  }
}

@include adaptive(mobile){
  .benefits{
    padding-top: 44px;
  }
}