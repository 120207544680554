.video{
  padding-top: 46px;
  padding-bottom: 100px;
  background: $white-default url('../img/video-bg.svg') center no-repeat;
  &__content{
    position: relative;
    margin-top: 33px;
    width: 100%;
    height: 600px;
    background: $black;
    border-radius: 10px;
  }
  &__btn{
    position: absolute;
    width: 50px;
    height: 70px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__brand-btn{
    min-height: 42px;
    padding: 5px 10px;
    margin: 51px auto 0;
  }
}

@include adaptive(tablet){
  .video{
    padding-top: 41px;
    padding-bottom: 103px;
    &__content{
      height: 370px;
    }
    &__brand-btn{
      margin-top: 25px;
    }
  }
}

@include adaptive(mobile){
  .video{
    padding-bottom: 61px;
    &__content{
      height: 175px;
    }
    &__btn{
      width: 24px;
      height: 34px;
    }
  }
}