.contacts{
  background: $grey;
  padding-top: 110px;
  padding-bottom: 90px;
  &__form{
    border-radius: 32px;
    background: $white-default;
    padding: 55px 53px 80px;
    max-width: 1430px;
    margin: 0 auto;
  }
  &__title{
    margin-bottom: 116px;
  }
  &__content{
    display: flex;
    justify-content: space-between;
    width: 970px;
    margin: 0 auto;
  }
  &__data{

  }
  &__rcaptcha{
    margin: 20px 0 10px auto;
    width: 304px;
  }
  &__error{
    font-size: 14px;
    color: $brand-red;
    padding-left: 12px;
    &--btn{
      text-align: right;
      margin: 10px 0;
    }
  }
  &__brand-btn{
    margin-left: auto;
    margin-right: 0;
    min-height: 42px;
    width: 200px;
    padding: 5px 15px;
    font-size: 16px;
    outline: none;
    border: none;
    &:disabled{
      opacity: .6;
    }
  }
}

@include adaptive(tablet){
  .contacts{
    &__title{
      margin-bottom: 68px;
    }
    &__form{
      width: 530px;
      padding-top: 34px;
      padding-bottom: 50px;
    }
    &__content{
      flex-direction: column;
      width: 100%;
    }
  }
}

@include adaptive(mobile){
  .contacts{
    padding-top: 44px;
    padding-bottom: 42px;
    &__title{
      font-size: 21px;
      margin-bottom: 39px;
    }
    &__form{
      width: 98%;
      padding: 25px 15px 40px;
    }
    &__brand-btn{
      margin-left: auto;
      margin-right: auto;
    }
  }
}