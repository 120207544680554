$black: #34394f;
$black-default: #000;
$white-default: #fff;
$grey: #f6f7f9;
$grey-dark: #3d4454;
$grey-border: #979797;
$grey-light: #dfe3ec;

$brand-purple: #5326dd;
$brand-red: #ee5d4c;
