.slider{
  display: flex;
  justify-content: space-between;
  position: relative;
  &__title{
    font-size: 32px;
    font-family: "Source Sans Pro", $font-stack;
    margin-bottom: 35px;
  }
  &__active{
    &-pic{
      box-shadow: 0 8px 20px rgba($black, 0.1);
      &--anim{
        animation: fade 1s linear;
      }
    }
  }
  &__content{
    position: absolute;
    left: 581px;
    top: 5px;
  }
  &__counter{
    font-size: 30px;
    letter-spacing: -3px;
    line-height: 1;
    margin-bottom: 25px;
    &-number{
      font-size: 28px;
      &--current{
        color: $brand-purple;
        font-size: 36px;
      }
    }
  }
  &__line{
    display: none;
  }
  &__desc{
    padding: 24px 20px 54px 0;
    min-width: 520px;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 730px;
  }
  &__list{
    margin-bottom: 35px;
  }
  &__arrows{
    display: flex;
    align-items: center;
    width: 265px;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    &-item{
      width: 100px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white-default;
      box-shadow: 0 8px 16px rgba($black, .31);
      border-radius: 100px;
      transition: all .3s;
      cursor: pointer;
      svg{
        transition: all .3s;
      }
      &:hover{
        background: $brand-purple;
        box-shadow: 0 8px 16px rgba($brand-purple, .31);
        .arrow-line{
          fill: $white-default;
        }
      }
      &--next{
        svg{
          transform: rotate(180deg);
        }
      }
    }
  }
  &__items{
    margin-top: 55px;
    display: flex;
    justify-content: space-between;
  }
  &__item{
    max-width: 280px;
    height: 180px;
    cursor: pointer;
    animation: fade 1s linear;
    &-pic{
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 20px;
      box-shadow: 0 8px 20px rgba($black, 0.1);
    }
    &-title{
      font-size: 18px;
      font-weight: 600;
      font-family: "Source Sans Pro", $font-stack;
    }
    &--current{
      display: none;
    }
  }
  &__list{
    &--hide{
      display: none;
    }
  }
}

@include adaptive(tablet){
  .slider{
    flex-direction: column-reverse;
    &__active{
      display: none;
    }
    &__content{
      position: relative;
      top: auto;
      left: auto;
      height: 337px;
    }
    &__items{
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition: left .3s;
    }
    &__item{
      min-width: 540px;
      height: 337px;
      margin-right: 30px;
      pointer-events: none;
      &-title{
        display: none;
      }
       &--current{
         display: block;
         pointer-events: none;
       }
    }
    &__arrows{
      display: none;
    }
    &__desc{
      min-height: 0;
      max-width: 100%;
      min-width: auto;
      padding-right: 0;
      padding-bottom: 0;
      padding-top: 46px;
    }
    &__list{
      margin-bottom: 0;
      max-width: 80%;
    }
    &__title{
      font-size: 24px;
      padding-left: 30px;
      margin-bottom: 31px;
    }
    &__counter{
      text-align: right;
      font-size: 18px;
      letter-spacing: -2px;
      position: relative;
      margin-bottom: 52px;
      &-number{
        font-size: 18px;
        &--current{
          font-size: 21px;
          font-weight: 700;
        }
      }
    }
    &__line{
      display: flex;
      position: absolute;
      height: 4px;
      width: 540px;
      background: $grey-light;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      padding: 0 28px;
      &-item{
        height: 4px;
        flex: 1;
        cursor: pointer;
        &--current{
          background: $brand-purple;
          pointer-events: none;
        }
      }
    }
  }
}

@include adaptive(mobile){
  .slider{
    &__item{
      min-width: 260px;
      height: 165px;
    }
    &__content{
      height: 165px;
    }
    &__desc{
      padding-top: 27px;
    }
    &__line{
      width: 242px;
    }
    &__title{
      font-size: 18px;
      padding-left: 0;
      margin-bottom: 15px;
    }
    &__counter{
      margin-bottom: 37px;
    }
  }
}

@keyframes fade {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}