.burger{
  width: 36px;
  height: 25px;
  position: relative;
  cursor: pointer;
  &__line{
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: $white-default;
    &:before, &:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      left: 0;
      right: 0;
      background: $white-default;
    }
    &:before{
      top: -11px;
    }
    &:after{
      bottom: -11px;
    }
    transition: background .3s;
    &:before, &:after{
      transition: all .3s;
    }
  }
  &--close{
    .burger__line{
      background: transparent!important;
      &:before{
        top: 0;
        -ms-transform: rotate(45deg) translate(-1px, 0px);
        -webkit-transform: rotate(45deg) translate(-1px, 0px);
        transform: rotate(45deg) translate(-1px, 0px);
      }
      &:after{
        bottom: -8px;
        -ms-transform: rotate(-45deg) translate(6px, -7px);
        -webkit-transform: rotate(-45deg) translate(6px, -7px);
        transform: rotate(-45deg) translate(6px, -7px);
      }
    }
  }
}

@include adaptive(mobile){
  .burger{
    width: 28px;
    height: 20px;
    &__line{
      height: 2px;
      &:after, &:before{
        height: 2px;
      }
      &:after{
        bottom: -9px;
      }
      &:before{
        top: -9px;
      }
    }
  }
}