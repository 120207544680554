
$laptop-width: 1700px;
$tablet-width: 1199px;
$mobile-width: 640px;

@mixin adaptive($size) {
  @if $size == laptop {
    @media (max-width: $laptop-width) {
      @content
    }
  } @else if $size == tablet {
    @media only screen and (max-width: $tablet-width) {
      @content
    }
  }  @else if $size == mobile {
    @media only screen and (max-width: $mobile-width) {
      @content
    }
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
  padding: {
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
  }
  //border: 1px solid red;
  &-fluid {
    width: 100%;
    padding: {
      left: 52px;
      right: 52px;
    }
  }
}

@include adaptive(laptop) {
  .container {
    width: 1140px;
  }
}

@include adaptive(tablet) {
  .container {
    width: 640px;
  }
}

@include adaptive(mobile) {
  .container {
    width: 320px;
  }
}