.brand-btn{
  width: 264px;
  max-width: 100%;
  min-height: 48px;
  background: $brand-purple;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white-default;
  font-size: 18px;
  font-family: "Source Sans Pro", $font-stack;
  font-weight: 600;
  padding: 12px 20px;
  cursor: pointer;
  &--mini{
    min-height: 42px;
    font-size: 16px;
    width: 200px;
    padding: 10px;
  }
}

@include adaptive(mobile){
  .brand-btn{
    font-size: 14px;
  }
}