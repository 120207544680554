$font-stack: "Open Sans", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;

@import "~normalize.css/normalize.css";

@import "basic/variables";
@import "basic/mixins";
@import "basic/grid";

h1, h2, h3, h4, p {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $black;
  outline: none;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.375;
  font-family: $font-stack;
  font-weight: 400;
  color: $black;
  background-color: #fff;
  overflow-x: hidden;
}

.main-title {
  font-size: 60px;
  font-weight: 700;
  font-family: "Source Sans Pro", $font-stack;
  margin-bottom: 25px;
  line-height: 1.2;
  &--light{
    color: $white-default;
  }
  &--nofull{
    max-width: 630px;
  }
}

.second-title {
  font-size: 46px;
  font-weight: 700;
  font-family: "Source Sans Pro", $font-stack;
  text-align: center;
  &--left{
    text-align: left;
  }
  &--light{
    color: $white-default;
  }
}

.main-text{
  font-size: 21px;
  line-height: 1.53;
  &--left{
    text-align: left;
  }
  &--nofull{
    max-width: 630px;
  }
}

.white-text{

}

.main-list{
  &--red{
    .main-list__item{
      &:before{
        background: $brand-red;
      }
    }
  }
}

.main-list__item{
  position: relative;
  font-size: 18px;
  padding-left: 30px;
  opacity: .8;
  line-height: 1.7;
  &:not(:last-child){
    margin-bottom: 37px;
  }
  &:before{
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $brand-purple;
    left: 1px;
    top: 13px;
  }
}

.default-input{
  margin-bottom: 19px;
  &__label{
    display: block;
    color: $grey-dark;
    position: relative;
    line-height: 1;
    margin-bottom: 9px;
    padding-left: 12px;
    &--required{
      &:after{
        content: '*';
        position: absolute;
        font-size: 16px;
        color: $brand-red;
        margin-left: 3px;
      }
    }
  }
  &__input{
    border: 1px solid $grey-border;
    border-radius: 21px;
    height: 42px;
    max-width: 100%;
    width: 370px;
    outline: none;
    padding: 0 12px;
    background: $white-default;
  }
  &__textarea{
    display: block;
    resize: none;
    outline: none;
    padding: 12px;
    width: 470px;
    max-width: 100%;
    height: 212px;
    border: 1px solid $grey-border;
    border-radius: 21px;
    background: $white-default;
  }
}

@include adaptive(tablet){
  .main-title, .second-title{
    font-size: 28px;
    text-align: center;
    line-height: 1.25;
  }
  .main-text{
    font-size: 16px;
    text-align: center;
    line-height: 1.65;
  }
  .default-input{
    &__input{
      width: 100%;
    }
    &__textarea{
      height: 142px;
    }
  }
  .main-list{
    &__item{
      font-size: 16px;
      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }
}

@include adaptive(mobile){
  .main-title, .second-title{
    font-size: 22px;
  }
  .main-text{
    font-size: 14px;
    br{
      display: none;
    }
  }
  .main-list{
    &__item{
      font-size: 14px;
      padding-left: 28px;
      line-height: 1.75;
      &:before{
        width: 5px;
        height: 5px;
      }
    }
  }
  .default-input{
    &__textarea{
      width: 100%;
    }
    &__label{
      font-size: 14px;
    }
    &__input{
      height: 35px;
    }
  }
}

@import "components/btns";
@import "components/logo";
@import "components/menu";
@import "components/burger";
@import "sections/header";
@import "sections/benefits";
@import "sections/features";
@import "components/slider";
@import "sections/video";
@import "sections/testimonials";
@import "sections/contacts";
@import "sections/footer";
@import "components/menu-popup";
@import "components/popup";

