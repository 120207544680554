.popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($white-default, .5);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  &--hide{
    opacity: 0;
    visibility: hidden;
  }
  &__content{
    position: relative;
  }
  &__close{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 42px;
    top: 42px;
    cursor: pointer;
    &:after, &:before{
      content: '';
      position: absolute;
      width: 25px;
      height: 2px;
      background: $black;
      border-radius: 3px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &:after{
      transform: rotate(45deg);
    }
    &:before{
      transform: rotate(-45deg);
    }
  }
  &__form{
    -webkit-box-shadow: 2px 3px 15px rgba($black-default, .27);
    -moz-box-shadow: 2px 3px 15px rgba($black-default, .27);
    box-shadow: 2px 3px 15px rgba($black-default, .27);
  }
}

@include adaptive(tablet){
  .popup{
    &__close{
      top: 26px;
      right: 26px;
    }
  }
}